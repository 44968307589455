import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { initializeGrid } from "../../../app/src/models/Grid/Grid";
import PageGrid from "../../../app/src/components/PageGrid/PageGrid";
import SEO from "../components/seo";

interface PageProps {
  data: any;
}

const ListingPage: React.FC<PageProps> = ({ data }) => {
  return (
    <>
      <SEO title={data.coremedia.content?.pageByPath?.title} />
      {data.coremedia.content.pageByPath?.grid && (
        <PageGrid {...initializeGrid(data.coremedia.content.pageByPath.grid)} />
      )}
    </>
  );
};

export default ListingPage;

export const query = graphql`
  query PageByPathQuery($pathName: String!) {
    coremedia {
      content {
        pageByPath(path: $pathName) {
          __typename
          id
          title
          grid {
            ...PageGrid
          }
        }
      }
    }
  }
`;
